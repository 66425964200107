/*
* decaffeinate suggestions:
* DS102: Remove unnecessary code created because of implicit returns
* Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
*/
import "summernote";
const sendFile = function(file, toSummernote) {
  const data = new FormData;
  data.append('upload[image]', file);
  return $.ajax({
    data,
    type: 'POST',
    url: '/uploads',
    cache: false,
    contentType: false,
    processData: false,
    success(data) {
      const img = document.createElement('IMG');
      img.src = data.url;
      img.setAttribute('id', `sn-image-${data.upload_id}`);
      return toSummernote.summernote('insertNode', img);
    }
  });
};

const deleteFile = file_id => $.ajax({
  type: 'DELETE',
  url: `/uploads/${file_id}`,
  cache: false,
  contentType: false,
  processData: false
});



$(document).on('turbolinks:load', () => $('[data-provider="summernote"]').each(function() {
  var createAmazonLink = function(context) {
    var modalHtml = `
        <div class="modal fade" id="isbnModal" tabindex="-1" role="dialog" aria-labelledby="isbnModalLabel">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="isbnModalLabel">ISBN-10コードを入力してください</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <input type="text" class="form-control" id="isbnInput" placeholder="ISBN-10コード">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">閉じる</button>
                        <button type="button" class="btn btn-primary" id="addImageBtn">画像を追加</button>
                    </div>
                </div>
            </div>
        </div>
    `;
    $('body').append(modalHtml);


    var ui = $.summernote.ui;
    var button = ui.button({
      contents: 'amazon',
      tooltip: 'create amazon link',
      container: $('.note-editor.note-frame'),
      click: function() {
        $('#isbnModal').modal('show');
      }
    });
    $('#addImageBtn').click(function() {
        var isbnInput = $('#isbnInput').val();

        if (isbnInput && isbnInput.length === 10) {
            // ISBN-10コードが有効な場合、Amazonの商品画像を取得
            var amazonUrl = `https://www.amazon.co.jp/dp/${isbnInput}?tag=3jiku05-22`;
            var imageUrl = `https://images.amazon.com/images/P/${isbnInput}.09_SL500_.jpg`;
            var editor = $(context.layoutInfo.editable);
            var imgHtml = `<a href="${amazonUrl}" target="_blank"><img src="${imageUrl}" width="125px" class="amazon"></a>`;
            editor.append(imgHtml);
            $('#isbnModal').modal('hide');
        } else {
            alert("無効なISBN-10コードです。");
        }
    });
    return button.render();
  }
  return $(this).summernote({
    lang: 'ja-JP',
    height: 400,
    fontNames: ["YuGothic","Yu Gothic","Hiragino Kaku Gothic Pro","Meiryo","sans-serif", "Arial","Arial Black","Comic Sans MS","Courier New","Helvetica Neue","Helvetica","Impact","Lucida Grande","Tahoma","Times New Roman","Verdana"],
    fontsize: '16',
    fontSizes:['12', '13','14', '15', '16', '17', '18', '20'],
    toolbar: [
      ['style', ['style']],
      ['font', ['bold', 'underline', 'clear']],
      ['fontname', ['fontname']],
      ['fontsize', ['fontsize']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['table', ['table']],
      ['insert', ['link', 'picture', 'video', 'amazon']],
      ['view', ['fullscreen', 'codeview', 'help']]
    ],
    buttons: {
      amazon: createAmazonLink
    },
    callbacks: {
      onImageUpload(files) {
        return sendFile(files[0], $(this));
      },
      onMediaDelete(target, editor, editable) {
        const upload_id = target[0].id.split('-').slice(-1)[0];
        if (!!upload_id) {
          deleteFile(upload_id);
        }
        return target.remove();
      }
    }
  });
}));
